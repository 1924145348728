/******************************************************************
Common.js :
This js file contains js funtions that will be used in all OH pages
******************************************************************/

"use strict";

var urlControl = (function ($) {
  function setURLParameter(param, value, isReplaceHistory) {
    var initHash = window.location.hash || window.location.search;
    var hash = initHash || getURLSymbol();
    var newParam = param + "=" + value;

    if (getURLParameter(param)) {
      var regx = new RegExp("(" + param + "=)[^&]+");
      hash = hash.replace(regx, "$1" + value);
    } else {
      hash += hash === getURLSymbol() ? newParam : "&" + newParam;
    }

    if (isReplaceHistory) {
      window.history.replaceState(null, "index", hash);
    } else {
      if (window.history.pushState) {
        window.history.pushState(null, "index", hash);
      } else {
        window.location.href = hash;
      }
      window.location.reload();
    }
  }

  function getURLParameter(param) {
    var results = new RegExp("[?#&]" + param + "=([^&#]*)").exec(
      window.location.href
    );
    return results ? results[1] : null;
  }

  function getURLSymbol() {
    var isBot =
      /bot|googlebot|bingbot|crawler|spider|robot|slurp|crawling/i.test(
        navigator.userAgent
      );
    var topicIdParamAvailable = /tpid=/i.test(window.location.href);
    return isBot || topicIdParamAvailable ? "?" : "#";
  }

  function getCategoryPage(category) {
    switch (category) {
      case "home":
        return "home-product.html";
        break;
    }
  }

  function getURLParamString(param, value, removeTrailing) {
    //this method return the parameters string ; e.g : ?param=123
    var initHash = window.location.hash || window.location.search; //get # or ? value
    var hash = initHash || getURLSymbol();
    var newParam = param + "=" + value;

    if (getURLParameter(param)) {
      var regx = new RegExp("(" + param + "=)[^&]+");
      hash = hash.replace(regx, "$1" + value);
    } else {
      hash += hash === getURLSymbol() ? newParam : "&" + newParam;
    }

    return removeTrailing ? getURLSymbol() + newParam : hash; //remove trailing params if true
  }

  return {
    setURLParameter: setURLParameter,
    getURLParameter: getURLParameter,
    getURLSymbol: getURLSymbol,
    getCategoryPage: getCategoryPage,
    getURLParamString: getURLParamString,
  };
})(jQuery);

var linkBuilder = (function ($) {
  //for SEO purpose, query string ("?" symbol) will be used

  function build(id) {
    var currentUrl = window.location.href.split(/[\?\#]/)[0]; // split if found # or ?
    var currentHash =
      urlControl.getURLParameter("tpid") ||
      window.location.href.split(/[\?\#]/)[1];
    currentHash = currentHash.split("/");
    if (currentHash[2] === "latest") {
      id = id
        .toString()
        .replace(productPage.getProductInfo().productVersion, "latest");
    }

    if (urlControl.getURLParameter("tpid")) {
      currentHash =
        currentHash[0] +
        "/" +
        currentHash[1] +
        "/" +
        currentHash[2] +
        "/" +
        currentHash[3] +
        "/" +
        id;
      currentHash = urlControl.getURLParamString("tpid", currentHash);
    } else {
      currentHash =
        urlControl.getURLSymbol() +
        currentHash[0] +
        "/" +
        currentHash[1] +
        "/" +
        currentHash[2] +
        "/" +
        currentHash[3] +
        "/" +
        id;
    }

    var newHash = currentHash; // + "/" + id;
    var link = currentUrl + newHash;
    return link;
  }

  function buildCustom(version, language, isWithID) {
    var pageID = "";
    var currentUrl = window.location.href.split(/[\?\#]/)[0];
    var currentHash =
      urlControl.getURLParameter("tpid") ||
      window.location.href.split(/[\?\#]/)[1];
    currentHash = currentHash.split("/");
    /*** TEMPORARY HELP-230 ***/
    if (isWithID) {
      var pageIDFull = currentHash[4] || "";
      pageID = pageIDFull.split("-")[0]; //get the exact page ID only
      if (pageID !== "") {
        var matcher = new RegExp(productPage.getProductInfo().product, "g");
        if (matcher.test(pageIDFull)) {
          pageID = pageID + "-" + productPage.getProductInfo().product;
        }

        //console.log("pageID "+pageID)
        pageID = pageID + "-" + version + "-" + language;
      }
    }
    /*** END ***/
    var newHash =
      currentHash[0] +
      "/" +
      currentHash[1] +
      "/" +
      version +
      "/" +
      language +
      "/" +
      pageID;
    newHash = urlControl.getURLParameter("tpid")
      ? urlControl.getURLParamString("tpid", newHash)
      : urlControl.getURLSymbol() + newHash;
    var link = currentUrl + newHash;
    return link;
  }

  function buildLanguage(language, isProductListPage) {
    var currentUrl = window.location.href.split(/[\?\#]/)[0];
    var currentHash =
      urlControl.getURLParameter("tpid") ||
      window.location.href.split(/[\?\#]/)[1];
    currentHash = currentHash.split("/");
    currentHash[3] = language;

    if (!!currentHash[4]) {
      var fullTopicID = currentHash[4];
      var partialTopicID = fullTopicID.split("-")[0];
      var productPath = currentHash[1];
      var version = currentHash[2];
      var matcher = new RegExp("-" + productPath + "-", "g");

      if (matcher.test(fullTopicID)) {
        currentHash[4] =
          partialTopicID + "-" + productPath + "-" + version + "-" + language;
      } else {
        currentHash[4] = partialTopicID + "-" + version + "-" + language;
      }
    }

    var newHash = urlControl.getURLParameter("tpid")
      ? urlControl.getURLParamString("tpid", currentHash.join("/"))
      : urlControl.getURLSymbol() + currentHash.join("/");

    if (isProductListPage) {
      newHash = urlControl.getURLParamString("lang", language);
    }

    var link = currentUrl + newHash;
    return link;
  }

  function buildProduct(category, path, version, language, topicId) {
    var currentUrl = window.location.href.split(/[\?\#]/)[0];
    var newHash = category + "/" + path + "/" + version + "/" + language;
    newHash += topicId ? "/" + topicId : "";
    newHash = urlControl.getURLParameter("tpid")
      ? urlControl.getURLParamString("tpid", newHash)
      : urlControl.getURLSymbol() + newHash;
    var link = currentUrl + newHash;
    return link;
  }

  return {
    build: build,
    buildCustom: buildCustom,
    buildLanguage: buildLanguage,
    bulldProduct: buildProduct,
  };
})(jQuery);

var localizePage = (function ($) {
  var fileLoaded = false;
  var fileNotfound = false;
  var langData;

  function loadLanguage(language, callback) {
    var languagePath = "data/language/" + language + ".json";
    $.getJSON(languagePath)
      .done(function (languagePath) {
        fileLoaded = true;
        langData = languagePath[0];

        if (_(callback).isFunction()) {
          callback();
        }
      })
      .fail(function () {
        fileNotfound = true;
      });
  }

  function fullLocalize(page) {
    if (fileLoaded) {
      switch (page) {
        case "front":
          localize(["common", "search", "front", "footer"]); 
          break;
        case "product":
          localize(["common", "search", "product", "footer"]);
          break;
        case "search":
          localize(["common", "search", "footer"]);
          break;
      }
    } else {
      if (!fileNotfound) {
        var timeout = window.setTimeout(function () {
          fullLocalize(page);
          clearTimeout(timeout);
        }, 200);
      }
    }
  }

  function localize(targets, customData) {
    //use strings (eg : "index:mainTitle") to direct target the element (refer to loc json)

    var data = customData || langData;

    function checkElementType(target, locString) {
      var $elems = $("[data-localize=" + target + "]");
      if ($elems.length) {
        $elems.each(function (index, elem) {
          var $elem = $(elem);
          if ($elem.is("input")) {
            $elem.attr("placeholder", locString);
          } else if ($elem.is("[data-original-title]")) {
            $elem.attr("data-original-title", locString);
            if ($elem.is("[data-toggle='popover']")) {
              $elem.text("").append(locString);
            }
          } else if ($elem.is("[data-tooltip]")) {
            $elem.attr("title", locString);
          } else {
            $elem.text("").append(locString);
          }
        });
      }
    }

    function traverse(val, key) {
      if (_(val).isObject()) {
        _(val).each(function (value, key) {
          traverse(value, key);
        });
      } else {
        checkElementType(key, val);
      }
    }

    if (_(targets).isString()) {
      var locTarget = targets.split(":");
      var searchKey = locTarget[0];
      _(data).each(function (value, key) {
        if (searchKey === key) {
          if (locTarget.length > 1) {
            localize(_(locTarget).rest(), value);
          } else {
            traverse(value, key);
          }
        }
      });
    } else {
      _(data).each(function (value, key) {
        if (_(targets).indexOf(key) !== -1) {
          traverse(value, key);
        }
      });
    }
  }

  function getLocalizedString(targets, customData) {
    //return the localized string/objects of specific key
    var data = customData || langData;
    var locTarget = targets.split(":");
    var searchKey = locTarget[0];
    var result = null;

    $.each(data, function (key, value) {
      if (searchKey === key) {
        result =
          locTarget.length > 1
            ? getLocalizedString(_(locTarget).rest().join(":"), value)
            : value;
        return false;
      }
    });

    return result;
  }

  function init() {
    var pagePathName = window.location.pathname;
    var currentPage = pagePathName
      .substring(pagePathName.lastIndexOf("/") + 1)
      .replace(".html", "");
    currentPage = currentPage || "index";

    if (currentPage.indexOf("index") !== -1) {
      var language = urlControl.getURLParameter("lang") || "en";
      loadLanguage(language, function () {
        fullLocalize("front");
      });
    }
    if (currentPage.indexOf("search") !== -1) {
      var language = urlControl.getURLParameter("lang") || "en";
      loadLanguage(language, function () {
        fullLocalize("search");
      });
    }
    if (currentPage.indexOf("error") !== -1) {
      var language = urlControl.getURLParameter("lang") || "en";
      loadLanguage(language, function () {
        fullLocalize("common");
      });
    }
    if (currentPage.indexOf("home") !== -1) {
      var language = urlControl.getURLParameter("lang") || "en";
      loadLanguage(language, function () {
        fullLocalize("front");
      });
    } else if (currentPage === "product") {
      var urlHash =
        window.location.hash.substring(1) ||
        window.location.search.substring(1);
      var language = urlHash.split("/")[3];
      loadLanguage(language, function () {
        fullLocalize(currentPage);
      });
    }
  }

  init();

  return {
    loadLanguage: loadLanguage,
    localizeAll: fullLocalize,
    localize: localize,
    getLocalizedString: getLocalizedString,
  };
})(jQuery);

/*******************
var dtmTrack = (function ($) {

  
  For further information on whats being tracked, please go to Online Help (Help Center) DTM console
  All Online Help v2 Data Elements start with "V2"
  All Event Based calls and Direct Call Rules start with "OHv2 :"
  
  Some variables are grabbed directly via DTM. e.g : Search Keywords, Channel, URL full path
  

  var trackVariables = {
    pageName: "",
    pageCategory: "",
    language: "",
    prevPageName: "no value",
    searchKeywords: ""
  };

  function trackPage(pageName, pageCategory, pageLanguage, searchKeywords) {
    var pageNameGlobalMarkers = "help:global:";
    var pageCategoryGlobalMarkers = "help:";

    trackVariables.pageName = (pageNameGlobalMarkers + pageName).substring(0, 80);
    trackVariables.pageCategory = pageCategoryGlobalMarkers + pageCategory;
    trackVariables.language = pageLanguage.split("-")[0]; //only follow ISO 639-1
    trackVariables.searchKeywords = "";

    try { //this is for tracking previous page name
      trackVariables.prevPageName = _satellite.readCookie("s_ppn") || "no value";
      _satellite.setCookie("s_ppn", trackVariables.pageName, 0.0208333); // 0.0208333 = 30min
    }
    catch (err) {
      console.error("SETCOOKIE: Couldn't call _satellite : " + err);
    }

    startTracking(searchKeywords);

  }

  function startTracking(keywords) {
    try {
      if (!!keywords) { //keyword not empty
        trackVariables.searchKeywords = keywords;
        _satellite.track("set_search_data"); //only for search page
      }
      else {
        _satellite.track("set_data");
      }
    } catch (err) {
      console.error("Couldn't call _satellite : " + err);
    }
  }

  return {
    startTracking: startTracking,
    trackPage: trackPage,
    variables: trackVariables
  };

})(jQuery);

********************/

var refreshIfBrowserIE = function () {
  //this is temporary for ie support. IE doesnt handle popstate for hash link hence the need of this function
  if (
    !urlControl.getURLParameter("tpid") &&
    (navigator.userAgent.indexOf("Trident/") !== -1 ||
      navigator.userAgent.indexOf("Edge/") !== -1)
  ) {
    window.location.reload();
  }
};

var hashSupportIE9 = (function ($) {
  // crude way to support IE9
  // Extend to IE10 -> IE11
  // IE9 and below doesnt support History API. hashchange is use instead
  // Extend IE9 support only in this object

  function unbindHashChange() {
    $(window).unbind("hashchange");
  }

  function bindHashChange() {
    window.setTimeout(function () {
      $(window).bind("hashchange", function () {
        if (/MSIE 9.0|MSIE 10|MSIE 11.0|rv:11.0/i.test(navigator.userAgent)) {
          refreshIfBrowserIE();
        }
      });
    }, 500);
  }

  bindHashChange();

  return {
    hashBind: bindHashChange,
    hashUnbind: unbindHashChange,
  };
})(jQuery);

/* WEB-816: remove old cookie consent as well 
var showCookieConsent = function (lang, category) {
  var btnColor = category === "home" ? "blue" : "primary";
  if (!$("#cookie-consent").length) {
    try {
      cookieconsent.init({
        lang: lang,
        submitBtnColor: btnColor,
      });
    } catch (err) {
      console.error("Cookie Consent not found " + err);
    }
  }
};
*/

var detectUserAgent = (function ($) {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
    //$("body").addClass("mobile");
  }
})(jQuery);

/*********** UI Components ************************/

var navBar = (function ($) {
  var $navBar = $("#navbar");
  var $navFront = $navBar.find(".navbar-header");
  var $navHome = $navBar.find(".nav-home");
  var $navBusiness = $navBar.find(".nav-business");
  var theme = document.body.classList; 
  var navsearch = document.getElementById("nav-search");
  var logo = document.querySelector(".navbar-brand img");
  var logolinkfs = document.querySelector(".navbar-brand");
  logolinkfs["href"] = "index.html";
  logo["src"] = "img/f-secure-logo.svg";
  logo["alt"] = "F-Secure"; 
  navsearch["action"] = "search.html";

  var setURLLang = function (lang) {
    function stripAndReplace(url, lang) {
      return url
        ? url.split(/[\?\#]/)[0] + urlControl.getURLSymbol() + "lang=" + lang
        : "lang=" + lang;
    }

    var $aFront = $navFront.children("a");
    var $aHome = $navHome.children("a");
    var $aBusiness = $navBusiness.children("a");
    var urlFront = $aFront.attr("href");
    var urlHome = $aHome.attr("href");
    var urlBusiness = $aBusiness.attr("href");
    $aFront.attr("href", stripAndReplace(urlFront, lang));
    $aHome.attr("href", stripAndReplace(urlHome, lang));
    $aBusiness.attr("href", stripAndReplace(urlBusiness, lang));
    searchForm.setLang(lang);
  }; 

  var setActive = function (category) {
    $navBar.find(".navbar li .active").removeClass("active");
    $navBar.find(".nav-" + category).addClass("active");
  };

  var mobileLogoHeader = function () {
    var logo = document.querySelector(".navbar-brand img");
  };

  var desktopLogoHeader = function () {
    var logo = document.querySelector(".second-level .navbar-brand img");
  };

  var logoHeaderSwitch = function () {
    if (theme[0] == "mobile" || theme[1] == "mobile") {
      //DCX-600 - Window.innerWidth to address edge case of mobile AND lg screen-width
      //resize EventListener to switch header layout difference between mobile + md screen-width and mobile + lg screen-width
      if (window.innerWidth < 1280) {
        mobileLogoHeader();
      } else {
        desktopLogoHeader();
      }

      window.addEventListener("resize", function () {
        if (window.innerWidth < 1280) {
          mobileLogoHeader();
        } else {
          desktopLogoHeader();
        }
      });
    } else {
      desktopLogoHeader();
    }
  };

  var expandSearchBar = function () {
    var $searchGroup = $(
      ".navbar-abs-right .search-option, .navbar-abs-right .search-field input, .search-option-wrapper"
    );
    var $navGroup = $(".navbar-group");
    $searchGroup.on("click", function (e) {
      e.stopPropagation();
      $navGroup.addClass("search-active");
      $("html").one("click", function (e) {
        $navGroup.removeClass("search-active");
      });
    });
  };

  var setFormElements = function () {
    var $navSearchForm = $navBar.find("#nav-search-form");
    var $navKeywords = $navBar.find("#nav-keywords-input");
    var $navSubmitButton = $navBar.find("#navbar-search-button");
    var $fieldLang = $navBar.find("#nav-lang-input");
    var $fieldVersion = $navBar.find("#nav-version-input");
    var $optionProduct = $navBar.find(".search-option");

    var $navxsSearchForm = $navBar.find("#nav-search");
    var $navxsSubmitButton = $navBar.find("#navbar-xs-search-button");

    function resize(e) {
      var $temp = $("<span class='invisible'>").html(
        $(".search-option").children("option:selected").text()
      );
      $temp.appendTo("body");
      $(".search-option").width($temp.width());
      $temp.remove();
    }

    function submit(e) {
      //HELP-273: checks Help-specific product section names with versions
      function findByVersion(source, version) {
        return source.filter(function (obj) {
          return +obj.version === +version;
        })[0];
      }

      if ($navKeywords.val().trim() !== "") {
        var coveoLang;
        var currLang = $fieldLang.val();
        var productName = $optionProduct.find("option:selected").val();
        var productVersion = $fieldVersion.val();
        var searchPageURL = "search.html";
        const langs = {
          bg: "Bulgarian",
          cs: "Czech",
          da: "Danish",
          de: "German",
          el: "Greek",
          en: "English",
          es: "Spanish",
          "es-mx": "Spanish (LatAm)",
          et: "Estonian",
          fi: "Finnish",
          fr: "French",
          "fr-ca": "French (CA)",
          hr: "Croatian",
          hu: "Hungarian",
          it: "Italian",
          ja: "Japanese",
          ko: "Korean",
          lt: "Lithuanian",
          nl: "Dutch",
          no: "Norwegian",
          pl: "Polish",
          pt: "Portuguese",
          "pt-br": "Portuguese (BR)",
          ro: "Romanian",
          ru: "Russian",
          sl: "Slovenian",
          sr: "Serbian",
          sv: "Swedish",
          tu: "Turkish",
          vi: "Vietnamese",
          "zh-cn": "Chinese (Simplified)",
          "zh-hk": "Chinese (Hong Kong)",
          "zh-tw": "Chinese (Taiwan)",
        };
        if (currLang in langs) {
          coveoLang = langs[currLang];
        } else {
          coveoLang = "English";
        }
        var url =
          searchPageURL +
          "?lang=" +
          currLang +
          "&#q=" +
          $navKeywords.val() +
          "&f-language=" +
          coveoLang +
          "&f-source=OnlineHelp";

        if (
          !!$optionProduct.length &&
          $optionProduct.find("option:selected").val() !== ""
        ) {
          url += "&f:@hlpproduct=[" + productName + "]";
        }

        window.location.href = url;
      }
    }

    var $searchOption = $(".search-option");
    $navSubmitButton.on("click", submit);
    $navSearchForm.on("keypress", function (e) {
      if (e.which === 13) {
        e.preventDefault();
        submit();
      }
    });
    $searchOption.on("change", resize);
    $(document).on("load ready", resize);
    $(window).on("load", resize);
  };

  var searchForm = (function ($) {
    var defLang;
    var defVersion;
    var $searchForm = $("#nav-search-form");
    var $fieldLang = $("#nav-lang-input");
    var $fieldVersion = $("#nav-version-input");
    var $fieldKeywords = $("#nav-keywords-input");
    var $optionProduct = $("#nav-product-select");

    var setFieldLang = function (lang) {
      $fieldLang.val(lang);
      defLang = lang;
    };

    var setFieldVersion = function (version) {
      $fieldVersion.val(version);
      defVersion = version;
    };

    var setFieldKeywords = function (keywords) {
      $fieldKeywords.val(keywords);
    };

    var setOptionProduct = function (product) {
      $optionProduct.attr("value", product);
    };

    var resetForm = function () {
      $searchForm[0].reset();
      setFieldLang(defLang);
    };

    return {
      setLang: setFieldLang,
      setVersion: setFieldVersion,
      setKeywords: setFieldKeywords,
      setProduct: setOptionProduct,
      reset: resetForm,
    };
  })(jQuery);

  //init
  setFormElements();
  expandSearchBar();
  logoHeaderSwitch(); 
  return {
    setURLLang: setURLLang,
    setActive: setActive,
    searchForm: searchForm,
  };
})(jQuery);

var footer = (function ($) {
  var $footer = $("#footer");
  var $supportLink = $footer.find(".foot-support [data-toggle='popover']");
  var $communityLink = $footer.find(".foot-community [data-toggle='popover']");

  var localizePopover = function (e) {
    $footer.find(".popover-title").remove();
    if ($(this).is($supportLink)) {
      localizePage.localize("footer:support:supportLinks");
    } else if ($(this).is($communityLink)) {
      localizePage.localize("footer:community:communityLinks");
    }
  };

  $supportLink.on("inserted.bs.popover", localizePopover);
  $communityLink.on("inserted.bs.popover", localizePopover);
})(jQuery);

/*********** UI Controller Class ******************/

var dropdownMenu = function ($targetMenu) {
  var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
    navigator.userAgent
  );
  this.isMobile = isMobile;
  var refreshIE = true; //refresh page after make selection (for ie)
  var $selectMenu;

  //click or select event
  var itemEvent = function (e) {
    var url = $(this).attr("href");
    window.location.href = url; 
    if (refreshIE) {
      setTimeout(refreshIfBrowserIE, 200); //delay the command for ie browsers
    }
  };  
   
  var openLangMenu = function (e) {
    e.preventDefault();   
    var $body = $(".theme-home");   
    $(e.target).siblings(".dropdown-menu-container").addClass("open") 
    if(isMobile == true){
      $body.addClass("modal-open")
    }
  };

  var closeLangMenu = function (e) {
    e.preventDefault();   
    var $body = $(".theme-home");   
    if(!$(e.target).is('.dropdown-menu') && !$(e.target).is('.lang-menu')){
      $targetMenu.children(".dropdown-menu-container").removeClass("open") 
    }
    if($body.hasClass("modal-open")){
      $body.removeClass("modal-open")
    }
  };

  var splitColumns = function (totalItem) {
    if (totalItem > 10) {
      var breakPoint = Math.ceil(totalItem / 2);
      $targetMenu
        .find(".dropdown-menu-container > .dropdown-menu > li:nth-child(" + breakPoint + ") a")
        .addClass("filler-bottom");
      $targetMenu
        .find(".dropdown-menu-container > .dropdown-menu > li:nth-child(" + (breakPoint + 1) + ") a")
        .addClass("filler-top"); 
    }
  };

  this.setCurrentValue = function (version) {
    $targetMenu
      .children(".dropdown-toggle")
      .html(version) 
      .children("a").on("click", itemEvent);
      $targetMenu
        .children(".dropdown-toggle").on("click", openLangMenu);
  };

  this.addMenuItem = function ($item) {  
      $targetMenu.children(".dropdown-menu-container").children(".dropdown-menu").append($item);   
  };

  this.addMenuItems = function ($items) { 
      var locChooseLangStr = localizePage.getLocalizedString("common:chooseLanguage") || "Choose language"; //grab string from cached  
      var langMenuHead  = $("<li class='lang-menu' data-localize='chooseLanguage'></li>").text(locChooseLangStr);
      var spanCloseMenu = $("<span id='close-lang-menu' class='close-lang-menu'></span>");
      $targetMenu.find(".dropdown-menu-container > .dropdown-menu").append(spanCloseMenu);
      $targetMenu.find(".dropdown-menu-container > .dropdown-menu").append(langMenuHead);
      $targetMenu.find(".dropdown-menu-container > .dropdown-menu").append($items);  
      $targetMenu.find(".dropdown-menu-container > .dropdown-menu a").on("click", itemEvent); 
      $targetMenu.children(".dropdown-menu-container").on("click", closeLangMenu);  
      splitColumns($items.length);   
  };

  this.addPlatformMenuItems = function ($items) { 
      $items.children("a").on("click", itemEvent); 
      $targetMenu.children(".dropdown-menu").append($items);
      splitColumns($items.length);  
  };
 
  this.removeIfHidden = function (isHide) {
    if (isHide) {
      $targetMenu.remove();
    }
  };

  this.addLocalizationHook = function (hook) {
    $targetMenu.children(".dropdown-toggle").attr("data-localize", hook);
  };

  this.getObj = function () {
    return $targetMenu;
  };

  this.disableRefresh = function () {
    refreshIE = false;
  }; 
};

/*********** UI Omni Components *******************/

var languagesLoc = [
  { code: "bg", loc: "Български" },
  { code: "cs", loc: "Čeština" },
  { code: "da", loc: "Dansk" },
  { code: "de", loc: "Deutsch" },
  { code: "el", loc: "Ελληνικά" },
  { code: "es", loc: "Español" },
  { code: "es-mx", loc: "Español (LatAm)" },
  { code: "et", loc: "Eesti" },
  { code: "fi", loc: "Suomi" },
  { code: "fr", loc: "Français" },
  { code: "fr-ca", loc: "Français (CA)" },
  { code: "hr", loc: "Hrvatski" },
  { code: "hu", loc: "Magyar" },
  { code: "it", loc: "Italiano" },
  { code: "ja", loc: "日本語" },
  { code: "ko", loc: "한국어" },
  { code: "lt", loc: "Lietuvių" },
  { code: "nl", loc: "Nederlands" },
  { code: "no", loc: "Norsk" },
  { code: "pl", loc: "Polski" },
  { code: "pt", loc: "Português" },
  { code: "pt-br", loc: "Português (BR)" },
  { code: "ro", loc: "Română" },
  { code: "ru", loc: "Русский" },
  { code: "sl", loc: "Slovenščina" },
  { code: "sr", loc: "српски" },
  { code: "sv", loc: "Svenska" },
  { code: "tr", loc: "Türkçe" },
  { code: "vi", loc: "Tiếng Việt" },
  { code: "zh-cn", loc: "中文 (简体)" },
  { code: "zh-hk", loc: "中文 (香港)" },
  { code: "zh-tw", loc: "中文 (繁體)" },
];

var omni = (function ($) {
  var $languagemenus = $(".language-menu");

  var languageMenus = new dropdownMenu($languagemenus);

  var localizeLanguage = function (langCode, langName) {
    var locLang = _(languagesLoc).findWhere({ code: langCode });
    return !!locLang ? locLang.loc : langName;
  };

  var updateLanguagesMenuURL = function () {
    if (!languageMenus.isMobile) {
      $languagemenus.find(".dropdown-menu a").each(function (index, obj) {
        $(obj).attr(
          "href",
          linkBuilder.buildLanguage($(obj).data("code").toString()).toString()
        );
      });
    } else {
      $languagemenus.find("select option").each(function (index, obj) {
        $(obj).attr("value", linkBuilder.buildLanguage($(obj).data("code")));
      });
    }
  };

  var loading = {
    show: function ($target, alignCenter) {
      var midAlignClass = alignCenter ? "middle" : "";
      var $load = $("<div id='loading'>").append(
        $("<span class='loading-icon " + midAlignClass + "'> </span>")
      );
      $target.prepend($load);
    },
    remove: function () {
      var time = window.setTimeout(function () {
        $("#loading").remove();
        clearTimeout(time);
      }, 500);
    }, 
  };

  var init = function () {
    // for unit testing purpose
    $languagemenus = $(".language-menu");
    languageMenus = new dropdownMenu($languagemenus);
  };

  return {
    reInit: init,
    languageMenus: languageMenus,
    localizeLanguage: localizeLanguage,
    updateLanguagesMenuURL: updateLanguagesMenuURL,
    loading: loading,
  };
})(jQuery);

//DCX-531 - Point footer items to country site in Public Web where applicable, or default to the Global site
var setFooterLinks = (function (e) {
  var $footer = $("#footer");
  $footer.find("a.footer-link.localize").each(function () {
    var urlEnd = $(this).attr("href");
    var baseUrl = "https://www.f-secure.com/";
    var checkLocale = window.location.hash.replace("#lang=", "");
    var mapCountrySites = {
      da: "dk-da",
      de: "de",
      es: "es",
      "es-mx": "mx-es",
      fi: "fi",
      fr: "fr",
      it: "it",
      ja: "jp-ja",
      nl: "nl",
      no: "no",
      pl: "pl",
      "pt-br": "br-pt",
      sv: "se-sv",
    };
    var urlLocale = "";
    if (checkLocale in mapCountrySites) {
      urlLocale = mapCountrySites[checkLocale] + "/";
    } else {
      urlLocale = "en/";
    }
    if (checkLocale !== "en") {
      $footer.find("a.footer-link.globalonly").parent().remove();
    }
    var newUrl = baseUrl.concat(urlLocale).concat(urlEnd);
    $(this).attr("href", newUrl);
  });
})(jQuery);

// DCX-616 - Display/hide specific footer items for certain languages
// If lang is FI or EN, display Demerger and Modern Slavery Statement links.
// If lang is DE, display Impressum link.
// If lang is JA, hide Countercept Portal link.
// If lang is IT, hide Elements Security Center link, display Modern Slavery Statement link.

var displayFooterLinks = (function (e) {
  var $footer = "";
  var Url = window.location.hash;
  var theme = document.body.classList;
  if (theme == "theme-business") {
    $footer = $("#footer.ws");
  } else {
    $footer = $("#footer.fs");
  }
  if (
    Url === "" ||
    ~Url.indexOf("en") ||
    ~Url.indexOf("fi") ||
    ~Url.indexOf("it")
  ) {
    $footer.find(".lang-fi").css("display", "inline-block");
    $footer.find(".footer-secondary .statement").css("display", "");
  } else {
    $footer.find(".lang-fi").css("display", "none");
    $footer.find(".footer-secondary .statement").css("display", "none");
  }

  if (~Url.indexOf("de")) {
    $footer.find(".lang-de").css("display", "inline-block"); 
    $footer.find(".footer-etc").css("display", "flex")
    $footer.find(".footer-etc").css("justify-content", "space-between")
  } else {
    $footer.find(".lang-de").css("display", "none");
  }

  if (~Url.indexOf("ja")) {
    $footer.find(".lang-ja").css("display", "none");
  } else {
    $footer.find(".lang-ja").css("display", "block");
  }

  if (~Url.indexOf("it")) {
    $footer.find(".lang-it").css("display", "none");
  } else {
    $footer.find(".lang-it").css("display", "block");
  }
})(jQuery);

// DCX-628 - Change href of For Investors link if lang is FI
var forInvestorsLinkFI = (function (e) {
  var Url = window.location.hash;
  var theme = document.body.classList;
  if (theme == "theme-home") {
    if (~Url.indexOf("fi")) {
      $("#footer.fs li.lang-fi-only a").attr(
        "href",
        "https://investors.f-secure.com/fi"
      );
    }
  }
})(jQuery);

/******** Utility Functions ***********/

var utility = (function ($) {
  var switchTheme = function (category) {
    var $body = $("body");
    var theme = "theme-home"; //default
    theme = category === "business" ? "theme-business" : theme;
    $body.removeClass("theme-home theme-business").addClass(theme);
  };

  var moveView = function (selector) {
    var selector = selector || "h1";
    var pageTitleOffset = $(selector).offset().top;
    if ($(window).scrollTop() > pageTitleOffset) {
      $("html,body").animate({ scrollTop: pageTitleOffset - 20 }, 200, "swing");
    }
  };

  var moveToContent = function (offset, animate) {
    animate = true || animate;
    if (animate) {
      $("html,body").animate({ scrollTop: offset - 20 }, 200, "swing");
    } else {
      $("html,body").scrollTop(offset - 20);
    }
  };

  var equalHeight = function ($targets) {
    var maxHeight = 0;
    $targets.each(function (i, target) {
      maxHeight =
        $(target).outerHeight() >= maxHeight
          ? $(target).outerHeight()
          : maxHeight;
    });
    $targets.css("height", maxHeight + "px");
  };

  var isMobile = function () {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
      navigator.userAgent
    );
  };

  var mqDetector = function (size) {
    return $(".fsg-mq-detector.visible-" + size).css("display") !== "none";
  };

  return {
    switchTheme: switchTheme,
    moveView: moveView,
    moveToContent: moveToContent,
    equalHeight: equalHeight,
    isMobile: isMobile,
    mqDetector: mqDetector,
  };
})(jQuery);

/******** JSON Utility **************/
// Use to load vital json files

var jsonUtility = (function ($) {
  var jsonHomeProducts;
  var jsonBusinessProducts;
  var jsonCombinedProducts;

  var loadProductListJsons = function (completedCallback, errorCallback) {
    $.when(
      $.getJSON("data/business-products.json"),
      $.getJSON("data/home-products.json")
    )
      .done(function (jsonBusinessProd, jsonHomeProd) {
        jsonHomeProducts = jsonHomeProd;
        jsonBusinessProducts = jsonBusinessProd;
        jsonCombinedProducts = {
          business: jsonBusinessProd[0],
          home: jsonHomeProd[0],
        };
        if (typeof completedCallback === "function") {
          completedCallback(jsonCombinedProducts);
        }
      })
      .fail(function (jqXHR, textStatus, err) {
        if (typeof errorCallback === "function") {
          errorCallback();
        }
      });
  };

  var getProductJson = function (type) {
    if (type === "home") {
      return jsonHomeProducts;
    } else if (type === "business") {
      return jsonBusinessProducts;
    }
  };

  var getCombinedProductJson = function () {
    return jsonCombinedProducts;
  };

  var getAllLanguages = function (combinedJson) {
    var jsonHomeProducts =
      combinedJson !== undefined ? combinedJson.home : jsonHomeProducts[0];
    var jsonBusinessProducts =
      combinedJson !== undefined
        ? combinedJson.business
        : jsonBusinessProducts[0];
    var combineList = $.merge(
      getLanguageList(jsonHomeProducts),
      getLanguageList(jsonBusinessProducts)
    );
    combineList = _.sortBy(removeDuplicate(combineList), "code");

    function getLanguageList(json) {
      var languagesArray = [];
      _.each(json, function (value) {
        _.each(value.versions, function (value) {
          languagesArray.push(value.language);
        });
      });
      return removeDuplicate(languagesArray);
    }

    function removeDuplicate(arr) {
      arr = _.flatten(arr);
      arr = _.uniq(arr, function (language) {
        return language.code;
      });
      return arr;
    }

    return combineList;
  };

  return {
    loadProductListJsons: loadProductListJsons,
    getProductJson: getProductJson,
    getCombinedProductJson: getCombinedProductJson,
    getAllLanguages: getAllLanguages,
  };
})(jQuery);

/******** URL Redirection **************/
// TEMPORARY HELP-230
// Redirect year-based version to the correct URL

var redirectVersions = [
  {
    product: "safe",
    versions: [
      { version: "2015", newVersion: "previous" },
      { version: "2016", newVersion: "previous" },
      { version: "2017", newVersion: "latest" },
    ],
  },
  {
    product: "safe-windows",
    versions: [
      { version: "2015", newVersion: "previous" },
      { version: "2016", newVersion: "previous" },
      { version: "2017", newVersion: "latest" },
    ],
  },
  {
    product: "safe-android",
    versions: [
      { version: "2015", newVersion: "previous" },
      { version: "2016", newVersion: "previous" },
      { version: "2017", newVersion: "latest" },
    ],
  },
  {
    product: "safe-mac",
    versions: [
      { version: "2015", newVersion: "previous" },
      { version: "2016", newVersion: "previous" },
      { version: "2017", newVersion: "latest" },
    ],
  },
  {
    product: "safe-ios",
    versions: [
      { version: "2015", newVersion: "previous" },
      { version: "2016", newVersion: "previous" },
      { version: "2017", newVersion: "latest" },
    ],
  },
  {
    product: "safe-wp",
    versions: [
      { version: "2015", newVersion: "previous" },
      { version: "2016", newVersion: "previous" },
      { version: "2017", newVersion: "latest" },
    ],
  },
  {
    product: "internet-security",
    versions: [
      { version: "2012", newVersion: "previous" },
      { version: "2013", newVersion: "previous" },
      { version: "2014", newVersion: "previous" },
      { version: "2015", newVersion: "previous" },
      { version: "2016", newVersion: "latest" },
    ],
  },
  {
    product: "anti-virus",
    versions: [
      { version: "2013", newVersion: "previous" },
      { version: "2014", newVersion: "previous" },
      { version: "2015", newVersion: "previous" },
      { version: "2016", newVersion: "latest" },
    ],
  },
  {
    product: "total",
    versions: [{ version: "2016", newVersion: "previous" }],
  },
];

var redirectChecker = function (rProduct, rVersion) {
  var rProducts = _(redirectVersions).findWhere({ product: rProduct });
  if (rProducts !== undefined) {
    return _(rProducts.versions).findWhere({ version: rVersion });
  } else {
    return rProducts;
  }
};

/*** Non-numerical version ***/

var uniqVersion = (function () {
  var uniqVersions = [
    { version: "latest", hook: "uvLatest" },
    { version: "previous", hook: "uvPrevious" },
    { version: "multi-platform", hook: "uvMPlatform" },
  ];

  var isUniqVersion = function (ver) {
    ver = ver.toLowerCase();
    var uniq = _(uniqVersions).findWhere({ version: ver });
    return !uniq ? false : true;
  };

  var getUniqHook = function (ver) {
    ver = ver.toLowerCase();
    var uniq = _(uniqVersions).findWhere({ version: ver });
    return uniq.hook;
  };

  return {
    isUniqVersion: isUniqVersion,
    getUniqHook: getUniqHook,
  };
})(jQuery);

/*** Subscribe to newsletter ***/

var subscribeNewsletter = (function () {
  var Url = window.location.hash;
  var subscribeButton = document.getElementById("subscribeButton");
  var newsletterModal = document.getElementById("newsletter");
  var newsletterSignupClose = document.getElementById("closeSignup");
  var newsletterForm = document.querySelector(".newsletter-container");
  var newsletterFormAction = document.querySelector("#newsletter iframe");

  subscribeButton.addEventListener("click", function (e) {
    if (subscribeButton.getAttribute("data-toggle") === "hide") {
      subscribeButton.setAttribute("data-toggle", "show");
      newsletterModal.classList.add("display");
      newsletterForm.classList.add("show");
    } else {
      subscribeButton.setAttribute("data-toggle", "hide");
      newsletterModal.classList.remove("display");
      newsletterForm.classList.remove("show");
    }
  });

  newsletterSignupClose.addEventListener("click", function (e) {
    subscribeButton.setAttribute("data-toggle", "hide");
    newsletterModal.classList.remove("display");
    newsletterForm.classList.remove("show");
  });

  if (~Url.indexOf("en") || !Url) {
    newsletterFormAction.src =
      "https://cloud.mail.f-secure.com/newsletter_signup_iframe?source=web";
  } else if (~Url.indexOf("de")) {
    newsletterFormAction.src =
      "https://cloud.mail.f-secure.com/newsletter_signup_iframe?lang=de&source=web";
  } else if (~Url.indexOf("fi")) {
    newsletterFormAction.src =
      "https://cloud.mail.f-secure.com/newsletter_signup_iframe?lang=fi&source=web";
  } else if (~Url.indexOf("sv")) {
    newsletterFormAction.src =
      "https://cloud.mail.f-secure.com/newsletter_signup_iframe?lang=sv&source=web";
  } else {
    subscribeButton.style.display = "none";
  }
})(jQuery);

/*** Brand elements responsive move ***/

var brandResponsive = (function () {
  var brandColumn = document.querySelector(".brandcolumn");
  var shieldColumn = document.querySelector(".shieldcolumn");
  var socials = document.querySelector(".social-icons-wrap");
  var subscribeButton = document.getElementById("subscribeButton");

  function adjustPlacement() {
    if (window.innerWidth >= 992) {
      brandColumn.append(subscribeButton);
      brandColumn.append(socials);
    } else {
      shieldColumn.after(socials);
      shieldColumn.after(subscribeButton);
    }
  }

  adjustPlacement();
  window.addEventListener("resize", function () {
    adjustPlacement();
  });
})(jQuery);
